const swiper = new Swiper(".swiper", {
    loop: true,
    slidesPerView: 3,
    // ページネーション
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    spaceBetween:35,
    // 前後の矢印
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        // スライドの表示枚数：500px以上の場合
        700: {
          slidesPerView: 3,
          spaceBetween:15,
          centeredSlides:true,
        },
        300: {
          slidesPerView: 1.5,
          spaceBetween:15,
          centeredSlides:true,
        }
      }
  });




  // ハンバーガーメニュー
  document.getElementById('btn_sp_menu').addEventListener('click', function() {
    document.getElementById('btn_sp_menu').classList.toggle('active');
    document.querySelector('.header_nav_block').classList.toggle('active');
    
  });


  // fade in 
  
  let fadeInTarget = document.querySelectorAll('.fade-in');
window.addEventListener('scroll', () => {
  for (let i = 0; i < fadeInTarget.length; i++){
    const rect = fadeInTarget[i].getBoundingClientRect().top;
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const offset = rect + scroll;
    const windowHeight = window.innerHeight; // 現在のブラウザの高さ
    if (scroll > offset - windowHeight + 150) {
      fadeInTarget[i].classList.add('scroll-in');
    }
  }
});


/*--

loading animation

---*/
document.addEventListener('DOMContentLoaded', () => {
  const loader = document.querySelector('.loading');
  const videoElement = document.querySelector('.loading-video'); // アニメーションのMP4動画要素
  const firstviewText = document.querySelector('.firstview h2');
  const firstviewDesc = document.querySelector('.firstview .desc');
  const circleAnime = document.querySelector('.firstview_circle');

  // 動画終了時にフェードアウトを実行
  videoElement.addEventListener('ended', () => {
      fadeOut(loader, 800, () => {
          firstviewText.classList.add('active');
          firstviewDesc.classList.add('active');
          circleAnime.classList.add('active');
      });
  });
});

// フェードアウトを実現するための関数
function fadeOut(element, duration, callback) {
  let opacity = 1;
  const interval = 40;
  const decrease = interval / duration;

  const fade = setInterval(() => {
      opacity -= decrease;
      if (opacity <= 0) {
          clearInterval(fade);
          element.style.display = 'none';

          if (callback) {
              callback();
          }
      }
      element.style.opacity = opacity;
  }, interval);
}